import React, { useEffect, useRef, useState } from 'react'
import { LineChart } from '../LineChart/LineChart'
import './styles.scss'
import optiWeighImage from '../../assets/img/OPTIWEIGH-LOGO.svg'

const OptiWeighAverageWeight = ({ data }) => {
  const [containerWidth, setContainerWidth] = useState(0)
  const containerRef = useRef()

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [containerRef])

  const handleResize = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth)
    }
  }
  return (
    <div className='lot-documents'>
      <div className='row'>
        <div className='row-title-wrap'>
          <p className='row-title'>Optiweigh - Mob Average Weight and ADG</p>
        </div>
        <div className='bordered-container'>
          <div ref={containerRef} className='optiweigh-container'>
            <LineChart data={data} />
            <img src={optiWeighImage} alt='opti-weight logo' className={`optiWeighLogo ${containerWidth <= 550 ? 'small' : containerWidth <= 880 ? 'middle' : ''}`} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OptiWeighAverageWeight

import React from 'react'
import { LotBoxContainer, LotBoxValue, TooltipContainer } from './styled'
import Tooltip from 'react-simple-tooltip'
import { css } from 'styled-components'

const LotBox = ({ type, value, measurement, tooltip }) => {
  return (
    <TooltipContainer>
      <Tooltip customCss={css`
      white-space: nowrap;
    `} content={tooltip} placement='top' background={'#2e2e2e'} radius={5} padding={8}>
        <LotBoxContainer type={type}>
          <LotBoxValue>{value}</LotBoxValue>
          <p>{measurement}</p>
        </LotBoxContainer>
      </Tooltip>
    </TooltipContainer>
  )
}

export default LotBox

import React, { useEffect, useRef, useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { line } from 'd3-shape'

export const ScoreChart = ({ fatScoreData }) => {
  const containerRef = useRef()
  const [data, setData] = useState(0)
  const [mobileWidth, setMobileWidth] = useState(false)
  const containerHeight = 55

  useEffect(() => {
    setData(fatScoreData.map((item, index) => ({
      key: index,
      title: item.percent + '%',
      value: item.percent
    })))
  }, [fatScoreData])

  useEffect(() => {
    if (containerRef.current) {
      setMobileWidth(containerRef.current.offsetWidth <= 550)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [containerRef])

  const handleResize = () => {
    setMobileWidth(containerRef.current.offsetWidth <= 550)
  }

  const XAxisLine = ({ bars, height }) => {
    const bottomLine = line()([[bars[0].x + bars[0].width / 2, height - 20], [bars[bars.length - 1].x + bars[bars.length - 1].width / 2, height - 20]])
    const rendered = []
    bars.forEach((bar, index) => {
      rendered.push(
        <path
          d={line()([[bar.x + bar.width / 2, height - 20], [bar.x + bar.width / 2, height - 32]])}
          fill='none'
          stroke={'#000'}
          style={{ pointerEvents: 'none' }}
        />
      )
      rendered.push(
        <text x={bar.x + bar.width / 2 - 3} y={height - 37} fontSize={12}>
          {index + 1}
        </text>
      )
    })
    return (
      <>
        <path
          d={bottomLine}
          fill='none'
          stroke={'#000'}
          style={{ pointerEvents: 'none' }}
        />
        {rendered}
      </>
    )
  }

  const BottomAxisLabels = ({ bars, height }) => {
    const rendered = []
    data.forEach((item, index) => {
      if (bars[index]) {
        rendered.push(
          <text x={bars[index].x + bars[index].width / 2 - 8} y={height} fontSize={15}>
            {item.title}
          </text>
        )
      }
    })
    return (
      <>
        {rendered}
      </>
    )
  }

  const ValuesHighlights = ({ bars, height }) => {
    const nonZeroIndexes = findNonZeroIndexes()
    const rendered = []
    data.forEach((item, index) => {
      if (index >= nonZeroIndexes.firstNonZeroIndex && index < nonZeroIndexes.lastNonZeroIndex) {
        rendered.push(
          <path
            d={line()([[bars[index].x + bars[index].width / 2, height - 5], [bars[index + 1].x + bars[index + 1].width / 2, height - 5]])}
            fill='none'
            stroke={'#93BCEF'}
            strokeWidth={20}
            style={{ pointerEvents: 'none' }}
          />
        )
      }
    })
    return (
      <>
        {rendered}
      </>
    )
  }

  const Points = ({ bars, height }) => {
    const nonZeroIndexes = findNonZeroIndexes()
    const rendered = []
    data.forEach((item, index) => {
      if (index >= nonZeroIndexes.firstNonZeroIndex && index <= nonZeroIndexes.lastNonZeroIndex) {
        rendered.push(
          <rect
            key={Math.random()}
            width={45}
            height={20}
            x={bars[index].x + bars[index].width / 2 - 10}
            y={height - 15}
            rx={4}
            ry={4}
            fill={'#93BCEF'}
          />
        )
      }
    })
    return (
      <>
        {rendered}
      </>
    )
  }

  const Title = ({ height }) => (
    <>
      <text x={-75} y={height / 2} fontSize={11} fontWeight={'bold'}>
        Fat Score
      </text>
    </>
  )

  const findNonZeroIndexes = () => {
    const firstNonZeroIndex = fatScoreData.findIndex((item) => item.percent !== 0)
    let lastNonZeroIndex = fatScoreData.length - 1
    for (let i = fatScoreData.length - 1; i >= 0; i--) {
      if (fatScoreData[i].percent !== 0) {
        lastNonZeroIndex = i
        break
      }
    }
    return {
      firstNonZeroIndex, lastNonZeroIndex
    }
  }
  return (
    <div ref={containerRef} style={{ height: containerHeight }}>
      <ResponsiveBar
        data={data}
        keys={['value']}
        indexBy='key'
        margin={{ right: mobileWidth ? 20 : 0, left: mobileWidth ? 80 : 60, bottom: 5 }}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        axisTop={null}
        axisRight={null}
        enableLabel={false}
        enableGridY={false}
        layers={['axes', Title, XAxisLine, Points, ValuesHighlights, BottomAxisLabels]}
        axisBottom={null}
        axisLeft={null}
        animate
      />
    </div>
  )
}

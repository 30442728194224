import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showSpinner } from '../../../actions'
import LotDetails from './LotDetails'

const mapStateToProps = (state, ownProps) => {
  return {
    userId: state.user.data._id
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showSpinner }, dispatch)
}

const LotDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LotDetails)

export default LotDetailsContainer
